import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"
import _imports_0 from '@/assets/images/fa-solid_angle-right.png'
import _imports_1 from '@/assets/images/Vector.png'


const _hoisted_1 = { class: "body_wrap about_page" }
const _hoisted_2 = { class: "question_game" }
const _hoisted_3 = { class: "container pb-5" }
const _hoisted_4 = {
  key: 0,
  class: "filter_outer mb-5"
}
const _hoisted_5 = {
  key: 0,
  class: "linear_progress"
}
const _hoisted_6 = {
  key: 1,
  class: "loader_overlay"
}
const _hoisted_7 = { class: "filter_header" }
const _hoisted_8 = { class: "topick_picker_content" }
const _hoisted_9 = { class: "topick_picker_img" }
const _hoisted_10 = ["src"]
const _hoisted_11 = { class: "mt-3 mb-3" }
const _hoisted_12 = { class: "mt-3 mb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_section = _resolveComponent("header-section")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_loader = _resolveComponent("loader")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _createVNode(_component_header_section),
      _createElementVNode("section", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          (_ctx.topic)
            ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                (_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_5, _cache[2] || (_cache[2] = [
                      _createElementVNode("div", { class: "line" }, null, -1),
                      _createElementVNode("div", { class: "subline inc" }, null, -1),
                      _createElementVNode("div", { class: "subline dec" }, null, -1)
                    ])))
                  : _createCommentVNode("", true),
                (_ctx.isLoading)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_6))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_7, [
                  _createElementVNode("h2", null, _toDisplayString(_ctx.currentPickerTitle), 1),
                  _createElementVNode("button", {
                    class: "next_btn",
                    onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onClickNext && _ctx.onClickNext(...args)))
                  }, _cache[3] || (_cache[3] = [
                    _createTextVNode(" Next "),
                    _createElementVNode("img", {
                      src: _imports_0,
                      alt: ""
                    }, null, -1)
                  ])),
                  _createElementVNode("button", {
                    class: "filter_close",
                    onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClose && _ctx.onClose(...args)))
                  }, _cache[4] || (_cache[4] = [
                    _createElementVNode("img", {
                      src: _imports_1,
                      alt: ""
                    }, null, -1)
                  ]))
                ]),
                _createElementVNode("div", _hoisted_8, [
                  _createElementVNode("div", _hoisted_9, [
                    _createElementVNode("img", {
                      src: _ctx.topic.image,
                      alt: ""
                    }, null, 8, _hoisted_10)
                  ]),
                  _createElementVNode("h3", _hoisted_11, _toDisplayString(_ctx.topic.title), 1),
                  _createElementVNode("p", null, _toDisplayString(_ctx.topic.description), 1),
                  _createElementVNode("div", _hoisted_12, [
                    _createVNode(_component_router_link, {
                      target: "_blank",
                      to: {
                  name: 'Topic',
                  params: { slug: _ctx.topic.slug, tab: _ctx.TopicPageTab.QUESTION },
                },
                      class: "cm_bg cm-btn text-white"
                    }, {
                      default: _withCtx(() => _cache[5] || (_cache[5] = [
                        _createTextVNode("View Topic "),
                        _createElementVNode("img", {
                          src: "assets/images/right-arrow-black.svg",
                          alt: "",
                          class: "ml-1"
                        }, null, -1)
                      ])),
                      _: 1
                    }, 8, ["to"])
                  ])
                ])
              ]))
            : _createCommentVNode("", true)
        ])
      ])
    ]),
    _createVNode(_component_loader, { show: _ctx.isPageLoading }, null, 8, ["show"])
  ], 64))
}